import styled, { css } from 'styled-components';
import { fontSize, lineHeight } from '../../../styles/settings/fonts';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, margins } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';

export const MobileMenuSecondLevel__mobileToggle__wrapper = styled.div`
	background-color: var(--grey5);
	padding-right: ${margins[16]};
	display: flex;
	flex-flow: row;
	align-items: center;

	${above(
		breakpoints[600],
		() => css`
			padding-right: ${margins[24]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			padding-right: ${margins[48]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-right: ${margins[80]};
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			padding-right: ${margins[120]};
		`
	)}
`;

export const MobileMenuSecondLevel__containerFirstLevel = styled.ul`
	padding: ${gap[0]};
	list-style-type: none;
	display: flex;
	flex-flow: column;
`;

export const MobileMenuSecondLevel__innerList = styled.ul`
	list-style: none;
	list-style-type: none;
	padding: ${gap[0]};
	background-color: var(--white);
`;

export const MobileMenuSecondLevel__containerRow = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: ${props => (props['data-herolink'] ? '0px' : '1px')};
		background-color: ${props =>
			props['data-rowtype'] && props['data-rowtype'] === 'hero'
				? 'var(--grey15)'
				: 'var(--grey30)'};
		margin-right: ${margins[16]};
		margin-left: ${margins[16]};

		${above(
			breakpoints[600],
			() => css`
				margin-right: ${margins[24]};
				margin-left: ${margins[24]};
			`
		)}

		${above(
			breakpoints[960],
			() => css`
				margin-right: ${margins[48]};
				margin-left: ${margins[48]};
			`
		)}

		${above(
			breakpoints[1440],
			() => css`
				margin-right: ${margins[80]};
				margin-left: ${margins[80]};
			`
		)}

		${above(
			breakpoints[1920],
			() => css`
				margin-right: ${margins[120]};
				margin-left: ${margins[120]};
			`
		)}
	}

	&:last-child:afer {
		height: 0px;
	}
`;

export const MobileMenuSecondLevel__accordionItem = styled.li`
	background-color: ${props => !props['data-herolink'] && 'pink'};

	&[data-state='open']:not([data-disabled]) {
		/* styles for all collapsed accordion items */
		& > section {
			&:after {
				height: 0px;
			}
		}
	}

	&[data-state='open'] {
		&[data-disabled] {
			/* styles for all collapsed accordion items */
			&:last-child > section {
				&:after {
					height: 0px;
				}
			}
		}
	}

	&[data-state='collapsed'] {
		/* styles for all collapsed accordion items */
		&:last-child > section {
			&:after {
				height: 0px;
			}
		}
	}
`;

export const MobileMenuSecondLevel__linkSecondLevel = styled.div`
	background-color: var(--grey5);
	padding: ${gap[16]} 0 ${gap[16]} ${gap[32]};
	display: flex;
	flex-flow: column;
	font-size: ${fontSize.s}px;
	font-family: synthese, sans-serif;
	font-weight: 400;
	line-height: ${lineHeight.s / fontSize.s};
	font-style: normal;
	color: var(--black);
	flex: 1;

	& + li {
		&:after {
			height: ${props => !props.canExpand && '0px'};
		}
	}

	& > a {
		margin: ${gap[0]};
		text-align: left;
	}
`;

export const MobileMenuSecondLevel__linkThirddLevel = styled.div`
	background-color: var(--grey15);
	padding: ${gap[16]} ${gap[0]} ${gap[16]} ${gap[48]};
	display: flex;
	flex-flow: column;
	font-size: ${fontSize.s}px;
	font-family: synthese, sans-serif;
	font-weight: 400;
	line-height: ${lineHeight.s / fontSize.s};
	font-style: normal;
	color: var(--black);
	flex: 1;
	text-align: left;

	& + li {
		&:after {
			height: ${props => !props.canExpand && '0px'};
		}
	}

	& > a {
		margin: ${gap[0]};
	}
`;

export const MobileMenuSecondLevel__mobileToggle = styled.button`
	background: none;
	border: none;
	padding: ${gap[8]};
	margin: ${gap[0]};
	margin-right: -${gap[8]};
	height: 48px;
	width: 48px;
	box-shadow: none;
	cursor: pointer;

	&:hover,
	&:focus {
		outline: 2px solid var(--primaryAccent);
	}

	&[aria-expanded='true'] {
		transform: rotate(180deg);
	}

	svg {
		width: 100%;
		height: 100%;
	}

	${above(
		breakpoints[1440],
		() => css`
			display: none;
			visibility: none;
		`
	)}
`;

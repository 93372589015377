import React from 'react';
import Browser from '../../../lib/browser';
import { StyledLinkMobileMenuFirstLevel } from './index.styled';

const LinkMobileMenuFirslLevel = ({
	url,
	dataGACategory,
	dataGAAction,
	dataGALabel,
	target,
	title,
}) => (
	<StyledLinkMobileMenuFirstLevel
		href={url}
		data-ga-action={dataGAAction}
		data-ga-category={dataGACategory}
		data-ga-label={dataGALabel}
		target={target}
		rel="noopener noreferrer"
		onClick={event => Browser.handleClick(event, url)}
	>
		{title}
	</StyledLinkMobileMenuFirstLevel>
);

export default LinkMobileMenuFirslLevel;

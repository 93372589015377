import styled, { css } from 'styled-components';
import { hover } from '../../../styles/tools/media';
import { fontSize, lineHeight } from '../../../styles/settings/fonts';
import { gap } from '../../../styles/settings/spacing';

export const StyledLink = styled.a`
	font-size: ${fontSize.s}px;
	font-family: synthese, sans-serif;
	font-weight: ${props => (props.linkType === 'hero' ? 400 : 200)};
	line-height: ${lineHeight.s / fontSize.s};
	font-style: normal;
	color: var(--black);
	text-decoration: none;
	width: auto;
	height: ${props => (props.linkType === 'hero' ? 'fit-content' : 'auto')};
	margin-bottom: ${gap[8]};

	${hover(() => css`
		&:hover {
			text-decoration: underline;
		}
	`)}
`;

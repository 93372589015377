import '@reach/accordion/styles.css';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion';
import DownSVG from '../../../svgs/UI/Down';
import LinkMegaMenu from '../../atoms/LinkMegaMenu';
import {
	MobileMenuSecondLevel__mobileToggle__wrapper,
	MobileMenuSecondLevel__innerList,
	MobileMenuSecondLevel__containerRow,
	MobileMenuSecondLevel__containerFirstLevel,
	MobileMenuSecondLevel__accordionItem,
	MobileMenuSecondLevel__linkSecondLevel,
	MobileMenuSecondLevel__mobileToggle,
	MobileMenuSecondLevel__linkThirddLevel,
} from './index.styled';

const MobileMenuSecondLevel = ({ rows }) => {
	const theme = useContext(ThemeContext);

	return (
		<Accordion collapsible multiple>
			<MobileMenuSecondLevel__containerFirstLevel>
				{rows.map(({ heroLink, linkRows }, i) => (
					<AccordionItem
						as={MobileMenuSecondLevel__accordionItem}
						disabled={heroLink.title === undefined}
						key={`MobileMenuSecondLevel__AccordionItem__${i}`}
					>
						{heroLink && (
							<MobileMenuSecondLevel__containerRow>
								<MobileMenuSecondLevel__linkSecondLevel
									// linkType={heroLink.type}
									canExpand={heroLink.title}
								>
									<LinkMegaMenu {...heroLink} type="hero" />
								</MobileMenuSecondLevel__linkSecondLevel>

								{linkRows.length > 0 && (
									<MobileMenuSecondLevel__mobileToggle__wrapper as="span">
										<AccordionButton
											as={MobileMenuSecondLevel__mobileToggle}
											aria-label="open menu section"
										>
											<DownSVG fill={theme.black} />
										</AccordionButton>
									</MobileMenuSecondLevel__mobileToggle__wrapper>
								)}
							</MobileMenuSecondLevel__containerRow>
						)}
						<AccordionPanel as={MobileMenuSecondLevel__innerList}>
							{linkRows.map((link, index) => (
								<MobileMenuSecondLevel__containerRow
									data-rowtype={index === link.length - 1 && 'no-height'}
									as="li"
									key={`MobileMenuSecondLevel__AccordionItem__AccordionPanel__${index}`}
								>
									<MobileMenuSecondLevel__linkThirddLevel>
										<LinkMegaMenu {...link} />
									</MobileMenuSecondLevel__linkThirddLevel>
								</MobileMenuSecondLevel__containerRow>
							))}
						</AccordionPanel>
					</AccordionItem>
				))}
			</MobileMenuSecondLevel__containerFirstLevel>
		</Accordion>
	);
};

export default MobileMenuSecondLevel;

import styled from 'styled-components';
import { gap } from '../../../styles/settings/spacing';
import { smBodyRegular } from '../Typography/index.styled';

export const StyledLinkRulertelAlt = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: -40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${gap[8]} ${gap[16]};
	background-color: var(--primaryBackground);
	z-index: 1;

	& > svg {
		height: 24px;
		width: 24px;
		margin-right: ${gap[8]};
	}
`;

export const StyledLinkRulertelAlt__link = styled(smBodyRegular)`
	color: var(--black);
	text-decoration: none;
	cursor: pointer;
`;

import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { fontSize, lineHeight } from '../../../styles/settings/fonts';
import { gap } from '../../../styles/settings/spacing';
import { above, hover } from '../../../styles/tools/media';

export const StyledLinkMobileMenuFirstLevel = styled.a`
	background-color: var(--white);
	padding: ${gap[16]} 0 ${gap[16]} ${gap[16]}!important;
	text-decoration: none;
	font-size: ${fontSize.s}px;
	font-family: synthese, sans-serif;
	font-weight: 400;
	line-height: ${lineHeight.s / fontSize.s};
	font-style: normal;
	color: var(--black);
	flex: 1;
	flex-basis: 100%;
	text-align: left;

	&:focus {
		text-decoration: underline;
	}

	${hover(
		() => css`
			&:hover {
				text-decoration: underline;
			}
		`
	)}

	${above(
		breakpoints[600],
		() => css`
			padding: ${gap[24]} 0 ${gap[24]} ${gap[24]}!important;
		`
	)}
`;

import React from 'react';
import { StyledLink } from './index.styled';
import Browser from '../../../lib/browser';

const Link = ({ url, dataGACategory, dataGAAction, dataGALabel, target, title, type }) => (
	<StyledLink
		href={url}
		data-ga-action={dataGAAction}
		data-ga-category={dataGACategory}
		data-ga-label={dataGALabel}
		target={target}
		rel="noopener noreferrer"
		linkType={type}
		onClick={event => Browser.handleClick(event, url)}
	>
		{title}
	</StyledLink>
);

export default Link;

// These styles use classNames that match Legacy Bruntwood in order to keep consistency
// The reason for this is that we migrated all the styles from the old site because we're still working on IA

import styled, { css } from 'styled-components';
import { fontSize, lineHeight } from '../../../styles/settings/fonts';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, margins } from '../../../styles/settings/spacing';
import { above, hover } from '../../../styles/tools/media';

export const StyledMobileMenu = styled.section`
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	position: fixed;
	overflow-y: auto;
	padding-bottom: 50vh;
	top: ${props => (props.doubleNav ? '48px' : '56px')};
	right: 0;
	left: 0;
	bottom: 0;
	display: ${props => (props.hidden ? 'none' : 'block')};
	opacity: ${props => (props.hidden ? '0' : '1')};
	transition: opacity 150ms ease-in;
	z-index: 100;

	${above(
		breakpoints[600],
		() => css`
			top: ${props => (props.doubleNav ? '64px' : '56px')};
		`
	)}

	@media (min-width: 1140px) {
		display: none;
	}
`;

export const MobileMenu__list = styled.ul`
	list-style: none;
	list-style-type: none;
	padding: ${gap[0]};
	background-color: var(--white);
	border-top: 2px solid var(--primaryAccent);
`;

export const MobileMenu__innerList = styled.section`
	padding: ${gap[0]};
	display: none;

	&[aria-expanded='true'] {
		display: block;
	}
`;

export const MobileMenu__containerFirstLevel = styled.li`
	display: flex;
	flex-flow: column;

	& > li:last-of-type {
		&:after {
			height: 0px;
		}
	}
`;

export const MobileMenu__accordionItem = styled.li`
	&[data-state='collapsed'] {
		/* styles for all collapsed accordion items */
		& > li:after {
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0;
			right: 0;
			height: 1px;
			background-color: ${props =>
				props.type && props.type === 'hero'
					? 'var(--grey15)'
					: 'var(--grey30)'};
			margin-right: ${margins[16]};
			margin-left: ${margins[16]};

			${above(
				breakpoints[600],
				() => css`
					margin-right: ${margins[24]};
					margin-left: ${margins[24]};
				`
			)}

			${above(
				breakpoints[960],
				() => css`
					margin-right: ${margins[48]};
					margin-left: ${margins[48]};
				`
			)}

			${above(
				breakpoints[1440],
				() => css`
					margin-right: ${margins[80]};
					margin-left: ${margins[80]};
				`
			)}

			${above(
				breakpoints[1920],
				() => css`
					margin-right: ${margins[120]};
					margin-left: ${margins[120]};
				`
			)}
		}
	}

	&[data-state='collapsed'] {
		&:last-of-type(li) {
			&:after {
				height: 0px;
			}
		}
	}
`;

export const MobileMenu__containerRow = styled.section`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	position: relative;

	&:last-of-type('li') {
		&:after {
			height: 0px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		height: ${props => (props['data-rowtype'] === 'no-height' ? '0px' : '1px')};
		background-color: ${props =>
			props['data-rowtype'] && props['data-rowtype'] === 'hero'
				? 'var(--grey15)'
				: 'var(--grey30)'};
		margin-right:${margins[16]};
		margin-left:${margins[16]};

		${above(
			breakpoints[600],
			() => css`
				margin-right: ${margins[24]};
				margin-left: ${margins[24]};
			`
		)}

		${above(
			breakpoints[960],
			() => css`
				margin-right: ${margins[48]};
				margin-left: ${margins[48]};
			`
		)}

		${above(
			breakpoints[1440],
			() => css`
				margin-right: ${margins[80]};
				margin-left: ${margins[80]};
			`
		)}

		${above(
			breakpoints[1920],
			() => css`
				margin-right: ${margins[120]};
				margin-left: ${margins[120]};
			`
		)}
	}
`;

export const MobileMenu__linkSecondLevel = styled.a`
	background-color: ${props =>
		props.type && props.type === 'hero' ? 'var(--grey15)' : 'var(--grey15)'};
	padding: ${gap[16]} ${gap[0]} ${gap[16]};
	${props => (props.type && props.type === 'hero' ? '1em' : '2em')};
	display: flex;
	flex-flow: column;
	font-size: ${fontSize.s}px;
	font-family: synthese, sans-serif;
	font-weight: ${props =>
		props.type && props.type === 'hero' ? '400' : '200'};
	line-height: ${lineHeight.s / fontSize.s};
	font-style: normal;
	color: var(--black);
	flex: 1;
`;

export const MobileMenu__mobileToggle = styled.button`
	background: none;
	border: none;
	padding: ${gap[8]};
	margin-top: ${gap[0]};
	margin-right: -${gap[8]};
	margin-bottom: ${gap[0]};
	margin-left: ${gap[0]};
	height: 48px;
	width: 48px;
	box-shadow: none;
	cursor: pointer;

	&:focus {
		outline: 2px solid var(--primaryAccent);
	}

	svg {
		width: 100%;
		height: 100%;
	}

	${hover(
		() => css`
			&:hover {
				outline: 2px solid var(--primaryAccent);
			}
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			display: none;
			visibility: none;
		`
	)}
`;

export const MobileMenu__mobileToggle__wrapper = styled.div`
	background-color: transparent;
	padding-right: ${margins[16]};
	display: flex;
	flex-flow: row;
	align-items: center;

	${above(
		breakpoints[600],
		() => css`
			padding-right: ${margins[24]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			padding-right: ${margins[48]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-right: ${margins[80]};
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			padding-right: ${margins[120]};
		`
	)}
`;

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PhoneSVG from '../../../svgs/UI/Phone';
import { StyledLinkRulertelAlt, StyledLinkRulertelAlt__link } from './index.styled';

const Link = props => {
	const theme = useContext(ThemeContext);

	return (
		<div style={{ position: 'relative' }}>
			<StyledLinkRulertelAlt {...props}>
				<PhoneSVG fill={theme.primaryAccent} />

				<StyledLinkRulertelAlt__link as="a" href="tel:08007310300" className="rulertel">
					0800 731 0300
				</StyledLinkRulertelAlt__link>
			</StyledLinkRulertelAlt>
		</div>
	);
};

export default Link;

export const fontSize = {
	xxxl: 48,
	xxl: 40,
	xl: 32,
	l: 24,
	m: 20,
	s: 16,
	xs: 12,
}

export const lineHeight = {
	xxxl: 64,
	xxl: 56,
	xl: 48,
	l: 36,
	m: 32,
	s: 24,
	xs: 20,
}

// TODO: add a pixels to rem function here or in the implementations (styled components or otherwise)

import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import UpSVG from '../../../svgs/UI/Up';
import DownSVG from '../../../svgs/UI/Down';
import LinkMobileMenuFirstLevel from '../../atoms/LinkMobileMenuFirstLevel';
import MobileMenuSecondLevel from '../../molecules/MobileMenuSecondLevel';
import {
	StyledMobileMenu,
	MobileMenu__list,
	MobileMenu__containerRow,
	MobileMenu__containerFirstLevel,
	MobileMenu__innerList,
	MobileMenu__mobileToggle,
	MobileMenu__mobileToggle__wrapper,
} from './index.styled';
import LinkRulertelAlt from '../../atoms/LinkRulertelAlt';

const MobileMenu = ({ hidden, doubleNav, selectedNavToggle, data, toggleName }) => {
	const theme = useContext(ThemeContext);
	const [selectedMobileMenu, setSelectedMobileMenu] = useState(false);

	return (
		<StyledMobileMenu hidden={hidden} doubleNav={doubleNav}>
			<MobileMenu__list>
				{data.map(
					(link, index) =>
						toggleName.includes(selectedNavToggle) && (
							<MobileMenu__containerFirstLevel
								key={`MobileMenu__firstLevel__${index}`}
							>
								<MobileMenu__containerRow data-rowtype="hero">
									<LinkMobileMenuFirstLevel {...link.headingLink} />

									{link.linkColumns.length > 0 && (
										<MobileMenu__mobileToggle__wrapper>
											<MobileMenu__mobileToggle
												onClick={() =>
													setSelectedMobileMenu(
														link.headingLink === selectedMobileMenu
															? null
															: link.headingLink
													)
												}
												aria-label={`Navigate to ${link.headingLink.title}`}
											>
												{link.headingLink !== selectedMobileMenu && (
													<DownSVG fill={theme.black} />
												)}
												{link.headingLink === selectedMobileMenu && (
													<UpSVG fill={theme.black} />
												)}
											</MobileMenu__mobileToggle>
										</MobileMenu__mobileToggle__wrapper>
									)}
								</MobileMenu__containerRow>

								{link.linkColumns.length > 0 && (
									<MobileMenu__innerList
										aria-expanded={
											link.headingLink === selectedMobileMenu || 'false'
										}
									>
										<MobileMenuSecondLevel rows={link.linkColumns} />
									</MobileMenu__innerList>
								)}
							</MobileMenu__containerFirstLevel>
						)
				)}
			</MobileMenu__list>
			<LinkRulertelAlt />
		</StyledMobileMenu>
	);
};

export default MobileMenu;
